import { SET_PAY, MAKE_PAY,SET_LOGIN,SET_IPActivity,SET_PAY_INFO, GET_PAY,APPROVE_CELL, SET_DISEASE, SET_PHYSICIAN,SET_CONSENT,MEMBER, GET_OTP, SET_PASS, SET_REQUEST,SET_CELL, SET_VIAL,SEARCH_PATIENT, SET_DOSE,SET_CLIENT,SHIP_COST, SHIP_LABEL } from "../actions/types";

const isEmpty = require("is-empty");

const initialState = {
  pay: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_PAY:
      return {
        ...state,
        page_name: action.payload.result
      };      
      case SET_CLIENT:
      return {
        ...state,
        client_info: action.payload.result
      };
      case SET_CELL:
      return {
        ...state,
        cell_info: action.payload.result
      };
      case APPROVE_CELL:
      return {
        ...state,
        cell_approve: action.payload.result
      };
      case SET_DOSE:
      return {
        ...state,
        dose_info: action.payload.result
      };
      case MAKE_PAY:
        return {
          ...state,
          payment_info: action.payload
        };
      case SET_LOGIN:
      return {
        ...state,
        res: action.payload.result
      };
      case SET_IPActivity:
      return {
        ...state,
        res: action.payload.result
      };
      case SEARCH_PATIENT:
      return {
        ...state,
        res: action.payload.result
      };
      case SET_PAY_INFO:
      return {
        ...state,
        payment_info: action.payload
      };
      case GET_OTP:
      return {
        ...state,
        res: action.payload.result
      };
      case SET_PASS:
      return {
        ...state,
        response: action.payload.result
      };
      case GET_PAY:
      return {
        ...state,
        pay_info: action.payload.result
      };
      case SET_DISEASE:
      return {
        ...state,
        disease_info: action.payload
      };
      case SET_PHYSICIAN:
      return {
        ...state,
        physician_info: action.payload
      };
      case SET_VIAL:
      return {
        ...state,
        vial_info: action.payload.result
      };
      case SET_CONSENT:
      return {
        ...state,
        consent_info: action.payload
      };
      case MEMBER:
        return {
          ...state,
          member_info: action.payload.result
        };      
      case SET_REQUEST:
      return {
        ...state,
        request_info: action.payload
      };
      case SHIP_COST:
        return {
          ...state,
          ship_cost: action.payload
        };
        case SHIP_LABEL:
        return {
          ...state,
          ship_label: action.payload.result
        };
    default:
      return state;
  }
}
