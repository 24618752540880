/* eslint-disable no-unused-vars */
import React, { Component , useRef}  from "react";
import  { Redirect } from 'react-router-dom'
// import  { useRef   }  from "react";
// import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from 'moment';
import validator from 'validator';
import MainLayout from "../layout/main-layout";
import Header from '../layout/Header';
import Left from '../layout/Left';
import DatePicker from "react-datepicker";
import valid from "card-validator";
import "react-datepicker/dist/react-datepicker.css";
import SignatureCanvas from 'react-signature-canvas'
import { getPhysician, getPaymentInfo, setDiseaseData ,verifyClientData } from '../../actions/payActions'
import { json } from "body-parser";
import NumberFormat from 'react-number-format';




class InfectiousDisease extends Component {

    constructor(props){
        super(props);
        this.state={
            error:'',
            errors:{},
            formData:{'submissionOption':'','WhiteGloveLabFee':399,'amount':399,Source:"ACT Physician Portal",PaymentMethod:"",CreditCardProcessingFee:0},
            login:true,            
            submission:"",
            client_test:"",
            client_info:"",
            Dob:"",
            card_last_gid:"",
            card_info:{},
            save_card:false,
            save_ach:false,
            update_card:false,
            loading:true,
            isChecked:false,
            verify_client:false,
            Payment_Type:"",
            update_card:false,
            update_ach:false,
            save_card:false,
            CreditCard:"",


        }
    }

    componentDidMount() {            
        var physician_id = localStorage.getItem('physician_id'); 
        if(physician_id){
            this.props.getPhysician(physician_id, this.props.history);    
            this.props.getPaymentInfo(physician_id, this.props.history);
        }    
         
        var physician_id = localStorage.getItem('physician_id');
        if(physician_id != ""){
            // console.log('imrn')
            this.setState({'physician_id':physician_id})
           
            
        }
                
    }

    numberFormat = (value) =>
        new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'USD'
        }).format(value);

    savecard = (e) => {
        if(this.state.save_card){
            this.setState({save_card:!this.state.save_card})
            this.state.formData['save_card'] = !this.state.save_card
        }
        else{
            this.setState({save_card:true})
    
            this.state.formData['save_card'] = true
        }
        console.log(this.state.formData);
    }

    saveAch = (e) => {
        if(this.state.save_ach){
            this.setState({save_ach:!this.state.save_ach})
            this.state.formData['save_ach'] = !this.state.save_ach
        }
        else{
            this.setState({save_ach:true})

            this.state.formData['save_ach'] = true
        }
    }

    onChange = (e) =>{
        if(e.target.id== "submissionOption"){
            this.state.formData[e.target.id] = e.target.value;
            this.setState({submission:e.target.value})
        }else{
            this.state.formData[e.target.id] = e.target.value;
            var a = e.target.id;
            this.setState({formData:{ ...this.state.formData,a:e.target.value}})
          
        }
        
        // console.log('form data => ',this.state.formData)
    }

    checkradio = (e) => {
        this.state.formData['WaiverNotice'] = e.target.value;
        this.setState({client_test:e.target.id})
        
    }

    checkPayment = (e) => {
        if(e.target.id == "credit_card"){
            this.state.formData['CreditCard'] = true; 
            this.setState({CreditCard:e.target.value})
            
            this.state.formData['PaymentMethod'] = 'Credit Card';
            this.state.formData['PaymentMethod'] = 'Credit Card';
            this.state.formData["Credit_Card_Number"] = this.state.card_info['Credit_Card_Number']
            this.state.formData["CVV"] = this.state.card_info['CVV']
            this.state.formData["Credit_Card_Holder_First_Name"] = this.state.card_info['Credit_Card_Holder_First_Name']
            this.state.formData["Credit_Card_Holder_Last_Name"] = this.state.card_info['Credit_Card_Holder_Last_Name']
            this.state.formData["Month_of_Expiry"] = this.state.card_info['Month_of_Expiry']
            this.state.formData["Year_of_Expiry"] = this.state.card_info['Year_of_Expiry']
            this.state.formData['CreditCardProcessingFee'] = 0.03 * this.state.formData['amount'];
            

        }

        if(e.target.id == "shipment"){
            this.state.formData['CreditCard'] = false; 
            this.setState({CreditCard:e.target.value})
            this.state.formData['PaymentMethod'] = 'Check';
            this.state.formData['CreditCardProcessingFee'] = 0 * this.state.formData['amount'];


            // console.log('CreditCard =>', this.state.CreditCard)
        }

        if(e.target.id == "ACH_payment"){
            this.state.formData['CreditCard'] = false; 
            this.setState({CreditCard:e.target.value})
            this.state.formData['PaymentMethod'] = 'ACH Payment';
            this.state.formData['PaymentMethod'] = 'ACH Payment';
            this.state.formData["Credit_Card_Number"] = ""
            this.state.formData["CVV"] = ""
            this.state.formData['Credit_Card_Holder_First_Name'] = ""
            this.state.formData['Credit_Card_Holder_Last_Name'] = ""
            this.state.formData['Month_of_Expiry'] = ""
            this.state.formData['Year_of_Expiry'] = ""
            this.state.formData['CreditCardProcessingFee'] = 0 * this.state.formData['amount'];



            // console.log('CreditCard =>', this.state.CreditCard)
        }

        this.setState({Payment_Type:e.target.value})
        this.state.formData['Payment_Type'] = e.target.value; 
        console.log('formdata ----- ',this.state.formData);

    }

    checkradio2 = (e) => {
        this.state.formData['client-info'] = e.target.id;
        this.setState({client_info:e.target.id})

        // console.log('client info = ', this.state.client_info)
        
    }

    checkRadio3 = (e) => {     
        if(this.state.update_card){
            this.setState({update_card:!this.state.update_card})
            this.state.formData["Credit_Card_Number"] = this.state.card_info['Credit_Card_Number']
            this.state.formData["CVV"] = this.state.card_info['CVV']
            this.state.formData["Credit_Card_Holder_First_Name"] = this.state.card_info['Credit_Card_Holder_First_Name']
            this.state.formData["Credit_Card_Holder_Last_Name"] = this.state.card_info['Credit_Card_Holder_Last_Name']
            this.state.formData["Month_of_Expiry"] = this.state.card_info['Month_of_Expiry']
            this.state.formData["Year_of_Expiry"] = this.state.card_info['Year_of_Expiry']
        }
        else{
            this.state.formData["Credit_Card_Number"] = ""
            this.state.formData["CVV"] = ""
            this.state.formData['Credit_Card_Holder_First_Name'] = ""
            this.state.formData['Credit_Card_Holder_Last_Name'] = ""
            this.state.formData['Month_of_Expiry'] = ""
            this.state.formData['Year_of_Expiry'] = ""

            this.setState({update_card:true})
        }
        
        // console.log('update_card =>', this.state.update_card)
    }

    

    datechange1(e){
        // new Date(e);
        this.state.formData['Dob'] = Moment(e).format('MM/DD/YYYY');
        // console.log('selected date =>', this.state.formData['Dob']);
        this.setState({Dob:e})
    }

    datechange2(e){
        // new Date(e);
        this.state.formData['ClientDob'] = Moment(e).format('MM/DD/YYYY');
        // console.log('selected date =>', this.state.formData['ClientDob']);
        this.setState({ClientDob:e})
    }

    

    componentWillReceiveProps(nextProps) {
        // console.log('nextProps ----------',nextProps); 

        if(nextProps.page_name.pay_info && this.state.update_card==false){
            this.state.formData['Credit_Card_Holder_First_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_First_Name__c
            this.state.formData['Credit_Card_Holder_Last_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_Last_Name__c
            this.state.formData['Credit_Card_Billing_Zip_Code'] = nextProps.page_name.pay_info.Credit_Card_Billing_Zip_Code__c
            this.state.formData['CVV'] = nextProps.page_name.pay_info.CVV__c
            this.state.formData['Credit_Card_Number'] = nextProps.page_name.pay_info.Credit_Card_Number__c
            this.state.formData['Month_of_Expiry'] = nextProps.page_name.pay_info.Month_of_Expiry__c
            this.state.formData['Year_of_Expiry'] = nextProps.page_name.pay_info.Year_of_Expiry__c
            this.setState({Credit_Card_Number:nextProps.page_name.pay_info.Credit_Card_Number__c})
            // this.state.formData['Account_Number'] = nextProps.page_name.pay_info.Account_Number__c
            this.state.formData['PhysicianFirstName'] = nextProps.page_name.pay_info.FirstName
            this.state.formData['PhysicianLastName'] = nextProps.page_name.pay_info.LastName
            // this.state.formData['Name_On_Account'] = nextProps.page_name.pay_info.Name_on_ACH_Account__c
            // this.state.formData['ABA_Routing_Number'] = nextProps.page_name.pay_info.ABA_Routing_Number__c
            // this.setState({card_info:nextProps.page_name.pay_info})
            this.state.card_info['Credit_Card_Number'] = nextProps.page_name.pay_info.Credit_Card_Number__c
            this.state.card_info['CVV'] = nextProps.page_name.pay_info.CVV__c
            this.state.card_info['Credit_Card_Holder_First_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_First_Name__c
            this.state.card_info['Credit_Card_Holder_Last_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_Last_Name__c
            this.state.card_info['Month_of_Expiry'] = nextProps.page_name.pay_info.Month_of_Expiry__c
            this.state.card_info['Year_of_Expiry'] = nextProps.page_name.pay_info.Year_of_Expiry__c
            this.state.card_info['PhysicianFirstName'] = nextProps.page_name.pay_info.FirstName
            this.state.card_info['PhysicianLastName'] = nextProps.page_name.pay_info.LastName
            this.state.card_info['Account_Number'] = nextProps.page_name.pay_info.Account_Number__c
            this.state.card_info['Name_On_Account'] = nextProps.page_name.pay_info.Name_on_ACH_Account__c
            this.state.card_info['ABA_Routing_Number'] = nextProps.page_name.pay_info.ABA_Routing_Number__c


            if(nextProps.page_name.pay_info.Account_Number__c){
                var acc_num = nextProps.page_name.pay_info.Account_Number__c;
                this.state.acc_num = acc_num.slice(acc_num.length - 4)
                this.state.formData['Account_Number'] = nextProps.page_name.pay_info.Account_Number__c
            }

            if(nextProps.page_name.pay_info.ABA_Routing_Number__c){
                this.state.formData['ABA_Routing_Number'] = nextProps.page_name.pay_info.ABA_Routing_Number__c
            }
            
            if(nextProps.page_name.pay_info.Name_on_ACH_Account__c){
                this.state.formData['Name_On_Account'] = nextProps.page_name.pay_info.Name_on_ACH_Account__c
            }
            
            if(nextProps.page_name.pay_info.Credit_Card_Number__c){
                var nu =  nextProps.page_name.pay_info.Credit_Card_Number__c
                this.state.card_last_gid = nu.slice(nu.length - 4)
            }
            this.setState({loading:false})
          } 
        if(nextProps.page_name.page_name){
            this.state.formData['PhysicianId'] = nextProps.page_name.page_name.Id
            this.state.formData['Id'] = nextProps.page_name.page_name.Physician_ID__c
            this.state.formData['PhysicianLastName'] = nextProps.page_name.page_name.Name 
            this.setState({loading:false})
        }

        if(nextProps.page_name.disease_info){
            this.setState({loading:false})
            // this.setState({payment_response:nextProps.page_name.disease_info})
            this.setState({transaction_id:nextProps.page_name.disease_info.TransactionId})
            if(nextProps.page_name.disease_info.error){
                this.setState({api_error:nextProps.page_name.disease_info.error})
            }else{
                this.setState({payment_response:nextProps.page_name.disease_info.page_name})
            }
            window.scrollTo(0, 0); 
        }

        if(nextProps.page_name.payment_info){
            this.setState({loading:false})
            
            this.setState({transaction_id:nextProps.page_name.payment_info.TransactionId})
            if(nextProps.page_name.payment_info.error){
                this.setState({api_error:nextProps.page_name.payment_info.error})
            }else{
                this.setState({payment_response:nextProps.page_name.payment_info.page_name})
            }
            
        }

        if(nextProps.page_name.client_info){
            this.setState({loading:false})
            var re = JSON.parse(nextProps.page_name.client_info)
            // console.log('response => ',re.error)
            if(re.error != null){
                let errors = {};
                if(this.state.client_info=="client_id"){
                document.getElementById("ClientId").value = ""; 
                document.getElementById("ClientLastname").value = "";                               
                this.state.formData['ClientId']="";
                this.state.formData['ClientLastname']="";
                errors["ClientId"] = "Please enter valid client id or last name";
                }else{
                    errors["ClientFirstName"] = "Please enter valid client id or last name";
                    this.state.formData['ClientFirstName']="";
                    this.state.formData['Clientlastname']="";
                    this.state.formData['ClientEmail']="";
                    // this.state.formData['ClientDob']="";
                    document.getElementById("ClientFirstName").value = ""; 
                    document.getElementById("Clientlastname").value = "";    
                    document.getElementById("ClientEmail").value = ""; 
                    // document.getElementById("ClientDob").value = "";    

                }
                this.setState({errors: errors});
            }else{
                this.setState({verify_client:true})
                this.setState({success_msg:"Client Details Successfully Confirmed"});  
                this.state.formData['infectiousDiseaseFormCompleted'] = re.infectiousDiseaseFormCompleted;

            }
            
            
        }

        


    }

    clear = () => {
        this.sigPad.clear()
        this.setState({ trimmedDataURL: null })
      }
    
    trim = () => {
    this.setState({ trimmedDataURL: this.sigPad.getTrimmedCanvas()
        .toDataURL('image/png') })
        this.state.formData['Signature'] = this.sigPad.getTrimmedCanvas().toDataURL('image/png');
        // console.log('img', this.sigPad.getTrimmedCanvas().toDataURL('image/png'));
        
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
            reject(error);
          }
        })
    }

    handleFileRead = async (event) => {
        const file = event.target.files[0]
        let errors = {};
        let formIsValid = true;
        // console.log('file size > ',file.size/1024 )
        if(file  && file.type == "application/pdf"){

            if(file.size/1024 <= '500'){


            

            const localFile = await fetch(file);
            // then create a blob out of it (only works with RN 0.54 and above)
            const fileBlob = await localFile.blob();
           
            const base64 = await this.convertBase64(file)

           
            
            this.state.formData['lab_report_file'] = base64;
            // this.state.formData['file'] =  a;
            event.target.className = "form-control" 
            // console.log('blob => ', base64)
            }else{
                formIsValid = false
                errors["lab_report_file"] = "File size too big. Please compress and upload under 500Kb";
                document.getElementById("lab_report_file").value = "";
                this.state.formData['lab_report_file']="";
            }
        }
        else{
            formIsValid = false
            errors["lab_report_file"] = "Invalid file format.";
            this.state.formData['lab_report_file']="";
            document.getElementById("lab_report_file").value = "";
            
        }

        // console .log('error => ', this.state.errors);
        this.setState({errors: errors});
       

    }


    checkAch = (e) => {
        this.setState({update_ach:!this.state.update_ach})
        
        if(this.state.update_ach){
            this.state.formData["Account_Number"] = this.state.card_info['Account_Number']
            this.state.formData["Name_On_Account"] = this.state.card_info['Name_On_Account']
            this.state.formData["ABA_Routing_Number"] = this.state.card_info['ABA_Routing_Number']
        }else{
            this.state.formData["Account_Number"] = ""
            this.state.formData["Name_On_Account"] = ""
            this.state.formData["ABA_Routing_Number"] = ""
            // this.setState({update_ach:true})

        }
        console.log('update_card =>', this.state.update_ach)


    }

    verifyClient = (e) => {
        e.preventDefault();    
        // window.scrollTo(0, 500);
          
        let fields = this.state.formData;
        let errors = {};
        let formIsValid = true;
        // let scroll = {};
        const yOffset = -30;
        if(this.state.client_info=="client_id"){

        if(!fields["ClientId"]){
            formIsValid = false;
            errors["ClientId"] = "Please enter Client Id";
            let element = document.getElementById("ClientId");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            this.setState({isChecked: false});
            element.className = "form-control error"
        }
        if(!fields["ClientLastname"]){
            formIsValid = false;
            errors["ClientLastname"] = "Please enter client last name";
            let element = document.getElementById("ClientLastname");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
            this.setState({isChecked: false});
        }
    }

    if(this.state.client_info=="full_contact"){

        if(!fields["ClientFirstName"]){
            formIsValid = false;
            errors["ClientFirstName"] = "Please enter client first name";
            let element = document.getElementById("ClientFirstName");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["Clientlastname"]){
            formIsValid = false;
            errors["Clientlastname"] = "Please enter client last name";
            let element = document.getElementById("Clientlastname");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["ClientEmail"]){
            formIsValid = false;
            errors["ClientEmail"] = "Please enter client email";
            let element = document.getElementById("ClientEmail");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["ClientDob"]){
            formIsValid = false;
            errors["ClientDob"] = "Please enter client Dob";
            let element = document.getElementById("ClientDob");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

    }
        this.setState({errors: errors});

        // console.log('check value => ',this.state.isChecked)
        if(formIsValid){
            // this.setState({ loading: true });  
            this.setState({isChecked: true});
            if(this.state.client_info=="client_id"){
                var newList = {ClientId:fields["ClientId"],ClientLastname:fields["ClientLastname"]}
            }else{
                var newList = {ClientFirstName:fields["ClientFirstName"],Clientlastname:fields["Clientlastname"],ClientEmail:fields["ClientEmail"],ClientDob:fields["ClientDob"]};
            }
           
           
            // console.log("newlist=>", newList);
            this.props.verifyClientData(newList, this.props.history);
            this.setState({loading:true})
            
        }




    }


    onSubmit = (e) => {              
        e.preventDefault();    
        // window.scrollTo(0, 500);
          
        let fields = this.state.formData;
        let errors = {};
        let formIsValid = true;
        // let scroll = {};
        const yOffset = -30;
        if(fields["submissionOption"] == "Waiver Submission"){
        if(!fields["Signature"]) {
            formIsValid = false;
            errors["Signature"] = "Please sign and click on check button.";
            // console.log('errors',errors);
            let element = document.getElementById("Signature");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }

        if(!fields["WaiverNotice"]) {
            formIsValid = false;
            errors["WaiverNotice"] = "Please Select.";
            // console.log('errors',errors);
            let element = document.getElementById("positive");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }

        
    }


        if(!fields["submissionOption"]){
            formIsValid = false;
            errors["submissionOption"] = "Please Select";
            let element = document.getElementById("submissionOption");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }
        
        

        if(!fields["agree"]){
            formIsValid = false;
            errors["agree"] = "Please Check ";
            let element = document.getElementById("agree");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            // element.className = "form-control error"
        }

        if(this.state.verify_client==false && this.state.client_info !=""){
            formIsValid = false;
            errors["verify_client"] = "Please confirm client details";
            let element = document.getElementById("verify_client");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            // this.setState({isChecked: false});
            // element.className = "form-control error"
        }

        if(this.state.client_info ==""){
            formIsValid = false;
            errors["client-info"] = "Please Check ";
            let element = document.getElementById("full_contact");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }

        if(this.state.client_info=="client_id"){

            if(!fields["ClientId"]){
                formIsValid = false;
                errors["ClientId"] = "Please enter Client Id";
                let element = document.getElementById("ClientId");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }
            if(!fields["ClientLastname"]){
                formIsValid = false;
                errors["ClientLastname"] = "Please enter client last name";
                let element = document.getElementById("ClientLastname");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }

        }

        if(this.state.client_info=="full_contact"){

            if(!fields["ClientFirstName"]){
                formIsValid = false;
                errors["ClientFirstName"] = "Please enter client first name";
                let element = document.getElementById("ClientFirstName");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }

            if(!fields["Clientlastname"]){
                formIsValid = false;
                errors["Clientlastname"] = "Please enter client last name";
                let element = document.getElementById("Clientlastname");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }

            if(!fields["ClientEmail"]){
                formIsValid = false;
                errors["ClientEmail"] = "Please enter client email";
                let element = document.getElementById("ClientEmail");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }

            if(fields["ClientEmail"] != "" && !validator.isEmail(fields["ClientEmail"]) ){
                formIsValid = false;
                errors["ClientEmail"] = "Please enter valid client email";
                let element = document.getElementById("ClientEmail");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }



            if(!fields["ClientDob"]){
                formIsValid = false;
                errors["ClientDob"] = "Please enter client Dob";
                let element = document.getElementById("ClientDob");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }

            

            if(fields["ClientDob"]){
                const dateFormat = 'MM-DD-YYYY';
                const yr = new Date(fields["ClientDob"]).getFullYear();
                const toDateFormat = Moment(new Date(fields["ClientDob"])).format(dateFormat);
                let validDate = Moment(toDateFormat, dateFormat, true).isValid();
                if(!validDate || yr >= 2050){
                formIsValid = false;
                        errors["ClientDob"] = "Invalid date ";
                    let element = document.getElementById("ClientDob");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                }
              }

        }



        if(this.state.submission=='White Glove Labs'){

            if(this.state.CreditCard == ""){
                formIsValid = false;
                errors["CreditCard"] = "Please select payment";
                let element = document.getElementById("shipment");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                
            }

            if(!fields["client-info"]){
                formIsValid = false;
                errors["client-info"] = "Please Select";
                let element = document.getElementById("full_contact");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                
            }

            if(!fields["Country"]){
                formIsValid = false;
                errors["Country"] = "Please enter Country";
                let element = document.getElementById("Country");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }
            
            if(!fields["City"]){
                formIsValid = false;
                errors["City"] = "Please enter City";
                let element = document.getElementById("City");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }
            if(!fields["State"]){
                formIsValid = false;
                errors["State"] = "Please enter State";
                let element = document.getElementById("State");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }
           
            if(!fields["ZipCode"]){
                formIsValid = false;
                errors["ZipCode"] = "Please enter Zip Code";
                let element = document.getElementById("ZipCode");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }

            // if(!fields["Dob"]){
            //     formIsValid = false;
            //     errors["Dob"] = "Please enter Dob";
            //     let element = document.getElementById("Dob");                
            //     const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            //     window.scrollTo({top: y, behavior: 'smooth'});
            //     element.className = "form-control error"
            // }

            if(!fields["FirstName"]){
                formIsValid = false;
                errors["FirstName"] = "Please enter first name";
                let element = document.getElementById("FirstName");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }
    
            if(!fields["LastName"]){
                formIsValid = false;
                errors["LastName"] = "Please enter last name";
                let element = document.getElementById("LastName");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }
            // if(!fields["Email"]){
            //     formIsValid = false;
            //     errors["Email"] = "Please enter email";
            //     let element = document.getElementById("Email");                
            //     const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            //     window.scrollTo({top: y, behavior: 'smooth'});
            //     element.className = "form-control error"
            // }

            if(this.state.formData['Payment_Type'] == "ACH") {
             
                if(!fields["Account_Number"] ){
                    formIsValid = false;
                    errors["Account_Number"] = "Please enter account number";
                    let element = document.getElementById("Account_Number");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                }
                if(!fields["Name_On_Account"] ){
                    formIsValid = false;
                    errors["Name_On_Account"] = "Field cann't be empty";
                    let element = document.getElementById("Name_On_Account");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                }
        
                if(!fields["ABA_Routing_Number"] ){
                    formIsValid = false;
                    errors["ABA_Routing_Number"] = "Field cann't be empty";
                    let element = document.getElementById("ABA_Routing_Number");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                }
            

            if(!fields["Account_Number"] ){
                formIsValid = false;
                errors["CreditCard"] = "Please store your ACH Details in the update payment information tab to use ACH Payment Method.";
                let element = document.getElementById("ACH_payment");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }
        
                
    
            }

            if(this.state.update_card == true){

                if(!fields["Credit_Card_Number"]){
                    formIsValid = false;
                    errors["Credit_Card_Number"] = "Please enter credit card number";
                    let element = document.getElementById("Credit_Card_Number");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                }
                if(fields["Credit_Card_Number"]) {
                    let cardvalid = valid.number(fields["Credit_Card_Number"]);
                    // console.log('cardvalid',cardvalid);
                    if(!cardvalid.isValid){
                        formIsValid = false;
                        errors["Credit_Card_Number"] = "Invalid credit card number.";
                        let element = document.getElementById("Credit_Card_Number");                
                        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                    }
                }
    
                if(!fields["CVV"]){
                    formIsValid = false;
                    errors["CVV"] = "Please enter CVV";
                    let element = document.getElementById("CVV");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                }

                if(fields["CVV"] && !/^\d{3,4}$/.test(fields["CVV"])) {
                    formIsValid = false;
                    errors["CVV"] = "Invalid CVV code"; 
                    let element = document.getElementById("CVV");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});   
                    element.className = "form-control error"            
                }
    
                if(!fields["Credit_Card_Holder_First_Name"]){
                    formIsValid = false;
                    errors["Credit_Card_Holder_First_Name"] = "Please enter first name";
                    let element = document.getElementById("Credit_Card_Holder_First_Name");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                }
    
                if(!fields["Credit_Card_Holder_Last_Name"]){
                    formIsValid = false;
                    errors["Credit_Card_Holder_Last_Name"] = "Please enter last name";
                    let element = document.getElementById("Credit_Card_Holder_Last_Name");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                    element.className = "form-control error"
                }

                if(!fields["Month_of_Expiry"]){
                    formIsValid = false;
                    errors["Month_of_Expiry"] = "Cannot be empty";
                    let element = document.getElementById("Month_of_Expiry");                
                        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                        window.scrollTo({top: y, behavior: 'smooth'});
                        element.className = "form-control error"
                } 
        
                
    
                if(!fields["Year_of_Expiry"]){
                    formIsValid = false;
                    errors["Year_of_Expiry"] = "Cannot be empty";
                    let element = document.getElementById("Year_of_Expiry");                
                        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                        window.scrollTo({top: y, behavior: 'smooth'});
                        element.className = "form-control error"
                    } 

            
        
                   
            }


           
            



        }

        if(this.state.submission=='Local Lab Submission'){

            if(!fields["lab_report_file"]){
                formIsValid = false;
                errors["lab_report_file"] = "Please select a pdf file";
                let element = document.getElementById("lab_report_file");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"              
            }
        }

        


        console.log('formIsValid --- ', formIsValid)

        this.setState({errors: errors});
        
        if(formIsValid){
            // this.setState({ loading: true });  
            this.state.formData['amount'] = this.state.formData['amount']+this.state.formData['CreditCardProcessingFee'];

            const newList = this.state.formData;
            // console.log("newlist=>", newList);
            this.props.setDiseaseData(newList, this.props.history);
            this.setState({loading:true})
        }

    }

    

    
    render() {
        const {formData} = this.state
        if(!sessionStorage.getItem('physician_id') || sessionStorage.getItem('physician_id')==null) {           
            return <Redirect to="../" />
          } 
        return (
            
            <MainLayout>
                <Header />
                { (this.state.loading)?
                <div class="loading-div">
                    <div class="loading-img"><img src="../images/spinner.svg" alt="" /></div>
                </div>:""}
                <div className="main-content">
                    <Left activeMenu={"infectious-disease"}/> 
                    <div className="right-section">
                    <div className="pagesubheader-wrap">
                        <div className="pagesubheader-row">
                            <div className="page-subheader-col-left">
                                <div className="title-avatar">
                                    <img src="images/physician-icon.svg" alt="" />
                                </div>
                                <div className="title-main">
                                    <span className="title-sub-text">Physician Portal</span>
                                    <h1>Client Infectious Disease Form</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="inner-content-area">
                    {(this.state.payment_response)?
                        <div>
                            <h5 style={{paddingTop:"10px"}}><i>Please check your email for order confirmation</i></h5>
                        </div>:  
                        <div>  


                        <div className="form-content-area">
                        <span style={{color: "red"}}>{this.state.api_error}</span>
                     
                        </div>



                        <div className="form-wrap glove-form">
                        <form className="needs-validation" noValidate onSubmit={this.onSubmit}>
                        <div className="row">

                        <div className="col-md-12">

                                <h2 className="title-2">Client Information</h2>
                                {/* <p>I agree to hold American Cell Technology harmless if i develop any infectious diseases at any time in the future subsequent to obtaining my stored cells.</p> */}
                                <h3 className="title-3 mb-3">Please confirm client information before proceeding.</h3>
                                <div className="choose-options-sec">
                                    <div className="custom-control custom-radio">
                                        <input type="radio" id="client_id" name="client-info" className="custom-control-input" onChange={this.checkradio2} />
                                        <label className="custom-control-label" for="client_id">Client ID and Last Name</label>
                                        </div>
                                        <div className="custom-control custom-radio">
                                        <input type="radio" id="full_contact" name="client-info" className="custom-control-input" onChange={this.checkradio2} />
                                        <label className="custom-control-label" for="full_contact">Client Full Contact Information</label>
                                        </div>
                                        <span style={{color: "red"}}>{this.state.errors["client-info"]}</span>
                                </div>

                                <div className="submission-detail-sec">
                                {(this.state.client_info=="client_id")?
                                
                                <div className="row">
                                    <br />
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Client ID <span className="required">*</span> </label>
                                        <input type="text" id="ClientId" className="form-control"   onChange={this.onChange} />
                                        <span style={{color: "red"}}>{this.state.errors["ClientId"]}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Last Name<span className="required">*</span> </label>
                                        <input type="text" id="ClientLastname" className="form-control"   onChange={this.onChange} />
                                        <span style={{color: "red"}}>{this.state.errors["ClientLastname"]}</span>
                                    </div>
                                </div>

                                


                                </div>:""}

                                {(this.state.client_info=="full_contact")?
                                <div className="row">
                                    <br />
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Client First Name <span className="required">*</span> </label>
                                        <input type="text" name="ClientFirstName" id="ClientFirstName" className="form-control" placeholder="" onChange={this.onChange} />
                                        <span style={{color: "red"}}>{this.state.errors["ClientFirstName"]}</span>

                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Client Last Name<span className="required">*</span> </label>
                                        <input type="text" name="Clientlastname" id="Clientlastname" className="form-control" placeholder="" onChange={this.onChange} />
                                        <span style={{color: "red"}}>{this.state.errors["Clientlastname"]}</span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Client Email Address <span className="required">*</span> </label>
                                        <input type="text" name="ClientEmail" id="ClientEmail" className="form-control" placeholder="" onChange={this.onChange} />
                                        <span style={{color: "red"}}>{this.state.errors["ClientEmail"]}</span>
                                        
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Client Date of Birth <span className="required">*</span> </label>
                                        {/* <input type="text" name="ClientDob" id="ClientDob" className="form-control" placeholder="Dob" onChange={this.onChange} /> */}
                                        <DatePicker id="ClientDob" className="form-control bggray" maxDate={new Date()} placeholderText="mm/dd/yyyy" selected={this.state.ClientDob}  onChange={date => this.datechange2(date)} /> <br />
                                        <span style={{color: "red"}}>{this.state.errors["ClientDob"]}</span>
                                    </div>
                                </div>
                                </div>:""}
                                {(this.state.client_info!="")?
                                <div className="form-group col-md-12">
                                <button type="button" className="btn btn-secondary" id="verify_client" onClick={this.verifyClient}>Confirm Client Details</button>
                                <br /><span style={{color: "red"}}>{this.state.errors["verify_client"]}</span>     
                                <span style={{color: "green",margin:"1%"}}> {this.state.success_msg}</span>                               
                                </div>:""}

                                </div>

                                
                                <br />
                            </div>
                            {( this.state.formData['infectiousDiseaseFormCompleted'] == true)?
                            <div className="col-md-12">
                                <span style={{color:"#0070D2"}}><b>Form is completed for this client.</b></span>
                            </div>:
                        <div>
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label><strong>Please choose from the following submission options</strong></label>
                                    <select className="form-control" id="submissionOption"  onChange={this.onChange}>
                                        <option value="">Please Select Submission Option</option>
                                        <option value="White Glove Labs">ACT White Glove Labs</option>
                                        <option value="Local Lab Submission">Local Lab Submission</option>
                                        <option value="Waiver Submission">Waiver Submission</option>
                                    </select>
                                    <span style={{color: "red"}}>{this.state.errors["submissionOption"]}</span>
                                </div>
                            </div>

                            <div className="col-md-12 submission-option-detail">
                            { (this.state.submission == "White Glove Labs")?
                            <div>
                                <h2 className="title-2">ACT White Glove Labs selection</h2>
                                <div className="submission-detail-sec">
                                    <h3 className="title-3 mb-4">Please provide shipping address for blood draw kit sent to you by ACT:</h3>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label">First Name <span className="required">*</span></label>
                                            <div className="field-col">
                                                <input type="text" className="form-control" id="FirstName" placeholder="" onChange={this.onChange} />
                                                <span style={{color: "red"}}>{this.state.errors["FirstName"]}</span>
                                            </div>
                                            
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label">Last Name <span className="required">*</span></label>
                                            <div className="field-col">
                                                <input type="text" className="form-control" id="LastName" placeholder="" onChange={this.onChange} />
                                                <span style={{color: "red"}}>{this.state.errors["LastName"]}</span>
                                            </div>
                                            
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label">Date Of Birth (mm/dd/yyyy) <span className="required">*</span></label>
                                            <div className="field-col">
                                            <DatePicker id="Dob" className="form-control bggray" maxDate={new Date()} placeholderText="mm/dd/yyyy" selected={this.state.Dob}  onChange={date => this.datechange1(date)} /> <br />
                                            
                                                <span style={{color: "red"}}>{this.state.errors["Dob"]}</span>
                                            </div>
                                            
                                            </div>
                                        </div> */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="col-form-label">Street <span className="required">*</span></label>
                                                <div className="field-col">
                                                <input type="text" className="form-control" id="Street" placeholder="" onChange={this.onChange} />
                                                <span style={{color: "red"}}>{this.state.errors["Street"]}</span>
                                                </div>
                                                
                                            </div>
                                            </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label">City <span className="required">*</span></label>
                                            <div className="field-col">
                                                <input type="text" className="form-control" id="City" placeholder="" onChange={this.onChange} />
                                                <span style={{color: "red"}}>{this.state.errors["City"]}</span>
                                            </div>
                                            
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="col-form-label">State <span className="required">*</span></label>
                                                <div className="field-col">
                                                <input type="text" className="form-control" id="State" placeholder="" onChange={this.onChange} />
                                                <span style={{color: "red"}}>{this.state.errors["State"]}</span>
                                                </div>
                                                
                                            </div>
                                            </div>
                                        

                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label">Zip Code <span className="required">*</span></label>
                                            <div className="field-col">
                                                <input type="text" className="form-control" id="ZipCode" placeholder="" onChange={this.onChange} />
                                                <span style={{color: "red"}}>{this.state.errors["ZipCode"]}</span>
                                            </div>
                                            
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label className="col-form-label">Country <span className="required">*</span></label>
                                            <div className="field-col">
                                            <input type="text" className="form-control" id="Country" placeholder="" onChange={this.onChange} />
                                            <span style={{color: "red"}}>{this.state.errors["Country"]}</span>
                                            </div>
                                            
                                            </div>
                                        </div>
                                        {/* <div className="col-md-12">
                                            <div className="form-group save-address-checkbox">
                                                <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="customCheck2" />
                                                <label className="custom-control-label" for="customCheck2">Saved Shipping Addresses</label>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>

                  

                        <div class="submission-detail-sec">
                        

                            <h3 class="title-3 mb-4">Please Choose a Payment Method :</h3>
                            <i>*Important Payment Information:
                            A 3% credit card processing fee will be added to all transactions paid via credit card. To avoid this fee, you may choose to pay via ACH. Thank you for trusting us to safely store your personal stem cells!</i>
                            <p></p>
                            <div class="choose-options-sec">
                            <div class="custom-control custom-radio">
                            <input type="radio" id="credit_card" name="CreditCard" class="custom-control-input" value="CC" onClick={this.checkPayment} />
                            <label class="custom-control-label" for="credit_card">Credit Card</label>
                            </div>
                            {/* <div class="custom-control custom-radio">
                            <input type="radio" id="shipment" name="CreditCard" class="custom-control-input" value="Shipment" onClick={this.checkPayment}/>
                            <label class="custom-control-label" for="shipment">Check included in sample shipment</label>
                            </div>   */}
                            <div class="custom-control custom-radio">
                            <input type="radio" id="ACH_payment" name="CreditCard" class="custom-control-input" value="ACH" onClick={this.checkPayment}/>
                            <label class="custom-control-label" for="ACH_payment">ACH Payment</label>
                            </div>                                        
                            </div>
                            <span style={{color: "red"}}>{this.state.errors["CreditCard"]}</span>
                            <br />

                            {(this.state.Payment_Type == "CC")? 
                               
                                    <div className="card">
                                        <div className="card-header">
                                        <h4>Current Payment Method Secured For Annual Banking </h4>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="customCheck2" defaultChecked />
                                            <label className="custom-control-label" htmlFor="customCheck2" />
                                        </div>
                                        </div>
                                        <div className="card-body">
                                        <div className="saved-card-detail">
                                            <div className="row">
                                            <div className="col-md-6">
                                                <label>Card Number</label>
                                                <div className="card-dtl-value">
                                                <img className="card-logo" src="images/master-card.png" alt="" />
                                                <span>Credit Card ending in {this.state.card_last_gid}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label>Name on card</label>
                                                <div className="card-dtl-value">
                                                <span>{this.state.card_info['Credit_Card_Holder_First_Name']} {this.state.card_info['Credit_Card_Holder_Last_Name']} </span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label>Expiry</label>
                                                <div className="card-dtl-value">
                                                <span>{this.state.card_info['Month_of_Expiry']} / {this.state.card_info['Year_of_Expiry']}</span>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" id="update_card" name="update_card" className="custom-control-input" value="true" onClick={this.checkRadio3}/>
                                            <label className="custom-control-label" for="update_card">Use Another Credit Card </label>
                                          
                                        </div>

                                        {(this.state.update_card == true)?
                                        <div className="card-edit-option">
                                            <div className="card-detail-form">
                                            <div className="card-detail-wrap mb-5">
                                                <h5>Credit Card Detail <img src="images/info.svg" data-toggle="tooltip" data-placement="top" title alt="" data-original-title="Tooltip Text" /></h5>
                                                <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label>Credit Card Number <span className="required">*</span> </label>
                                                    <input type="text" id="Credit_Card_Number" className="form-control" placeholder="" onChange={this.onChange} />
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Number"]}</span>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-3">
                                                    <div className="form-group">
                                                    <label>Expiry Date <span className="required">*</span> </label>
                                                    <input type="text" className="form-control" placeholder="-- --  /  -- -- -- --" />
                                                    <span style={{color: "red"}}>{this.state.errors["CreditCard"]}</span>
                                                    </div>
                                                </div> */}

<div className="col-md-4 mb-3">
                                            <label htmlFor="Month_of_Expiry">Card Expiration Date <span className="act_required">*</span></label>
                                            <select className="custom-select d-block w-100" id="Month_of_Expiry" onChange={this.onChange}  placeholder="mm" >
                                                <option value="">Select Month</option>
                                                <option value="01">01</option>
                                                <option value="02">02</option> 
                                                <option value="03">03</option> 
                                                <option value="04">04</option> 
                                                <option value="05">05</option>    
                                                <option value="06">06</option> 
                                                <option value="07">07</option> 
                                                <option value="08">08</option> 
                                                <option value="09">09</option> 
                                                <option value="10">10</option> 
                                                <option value="11">11</option>
                                                <option value="12">12</option>  
                                            </select>
                                            {/* <input type="text" className="form-control" id="Month_of_Expiry" onChange={this.onChange} placeholder="mm" required /> */}
                                            <span style={{color: "red"}}>{this.state.errors["Month_of_Expiry"]}</span>
                                            <div className="invalid-feedback">
                                            Valid Month required
                                            </div>
                                            </div>

                                            <div className="col-md-2 mb-3">
                                            <label htmlFor="creditCardExpirationYear"> &nbsp;</label>
                                            <select className="custom-select d-block w-100" id="Year_of_Expiry" onChange={this.onChange}  placeholder="YYYY" >
                                            <option value="">Year</option>
                                           
                                            <option value="2025">2025</option>
                                            <option value="2026">2026</option>
                                            <option value="2027">2027</option>
                                            <option value="2028">2028</option>
                                            <option value="2029">2029</option>
                                            <option value="2030">2030</option>
                                            <option value="2031">2031</option>
                                                <option value="2032">2032</option>
                                            </select>
                                            {/* <input type="text" className="form-control" id="creditCardExpirationYear" onChange={this.onChange} placeholder="yy" required /> */}
                                            
                                            <span style={{color: "red"}}>{this.state.errors["Year_of_Expiry"]}</span><div className="invalid-feedback">
                                            Valid Year required
                                            </div>
                                            </div>


                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                    <label>CVV <span className="required">*</span> </label>
                                                    <input type="password" id="CVV" className="form-control" placeholder="***" onChange={this.onChange} />
                                                    <span style={{color: "red"}}>{this.state.errors["CVV"]}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="card-logo-list">
                                                    <div><img src="images/card-1.svg" alt="" /></div>
                                                    <div><img src="images/card-2.svg" alt="" /></div>
                                                    <div><img src="images/card-3.svg" alt="" /></div>
                                                    <div><img src="images/card-4.svg" alt="" /></div>
                                                    </div>
                                                </div>
                                                </div>
                                                <div>                                            
                                                    <div className="custom-control custom-checkbox">
                                                    <br />
                                                    <input type="checkbox" id="save_card" name="save_card" className="custom-control-input" value="true" onClick={this.savecard}/>
                                                    <label className="custom-control-label" for="save_card">Save this credit card on file for future payments</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-detail-wrap">
                                                <h5>Billing Address <img src="images/info.svg" data-toggle="tooltip" data-placement="top" title alt="" data-original-title="Tooltip Text" /></h5>
                                                <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label>First Name</label>
                                                    <input type="text" id="Credit_Card_Holder_First_Name" className="form-control" placeholder onChange={this.onChange} />
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Holder_First_Name"]}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label>Last Name</label>
                                                    <input type="text" id="Credit_Card_Holder_Last_Name" className="form-control" placeholder onChange={this.onChange} />
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Holder_Last_Name"]}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label>Country</label>
                                                    <input type="text" id="Credit_Card_Holder_Country" className="form-control" placeholder onChange={this.onChange} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label>Zip/Postal Code</label>
                                                    <input type="text" id="Credit_Card_Holder_Zip"  className="form-control" placeholder onChange={this.onChange} />
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        :""}
                                        <div className="act-total">
                                        <p>ACT White Glove Labs :  {this.numberFormat(this.state.formData['amount'])} </p>
                                        <p>Processing Fee (3%) : {this.numberFormat(this.state.formData['CreditCardProcessingFee'])} </p>      
                                        <p>Total: {this.numberFormat(this.state.formData['amount']+ this.state.formData['CreditCardProcessingFee'])}   </p>
                                        </div>

                                        </div>
                                    </div>
                            :""}

                            {(this.state.Payment_Type == "ACH")?    
                             <div className="card">
                                <div className="card-header">
                                <h4>ACH Payment Method Secured For Banking </h4>   
                                                                    
                                </div>  
                                <div className="card-body">
                                {(this.state.card_info['ABA_Routing_Number'] ||  this.state.card_info['Account_Number'])?

                                    <div>
                                    <div className="saved-card-detail">                     
                                    <div class="row">          
                                        {/* <h2 className="title-2">ACH Billing Information</h2> */}
                                        <div class="col-md-4">
                                        <label> Name On Account</label>
                                        <div class="card-dtl-value">
                                        <span>{this.state.card_info['Name_On_Account']}</span>
                                        </div>
                                        </div>             
                                        <div class="col-md-4">
                                        <label>Account Number</label>
                                        <div class="card-dtl-value">
                                        <span>Account Number Ending in {this.state.acc_num}
                                        </span>
                                        </div>
                                        </div>

                                        <div class="col-md-4">
                                        <label>ABA Routing Number</label>
                                        <div class="card-dtl-value"> 
                                        <span>{this.state.card_info['ABA_Routing_Number']}
                                        </span>
                                        </div>
                                        </div>
                                    
                                    
                                    </div>
                                    </div>
                                    <br />
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" id="update_ach" name="update_ach" className="custom-control-input" value="true" onClick={this.checkAch}/>
                                        <label className="custom-control-label" for="update_ach">Use Another ACH </label>
                                        
                                    </div>

                                    <br />
                                    <div lassName="custom-control">
                                        {(!this.state.card_info['ABA_Routing_Number'] ||  !this.state.card_info['Account_Number'])?
                                        <span><b style={{color: "red"}}>Please store your ACH Details in the <a className="updapte_pay" href="./payment-information">update payment information</a> tab to use ACH Payment Method.</b></span>
                                         
                                        :""}
                                     </div>
                                     </div>
                                :""}

                                   
                                    {(!this.state.card_info['ABA_Routing_Number'] ||  !this.state.card_info['Account_Number'] || this.state.update_ach)?
                                        <div className="">   
                                            <div className="card-detail-form">
                                            <div class="row">
                                         <div class="col-md-6">
                                             <div class="form-group">
                                                 <label>Name On Account <span class="required">*</span> </label>
                                                 <input type="text" id="Name_On_Account" class="form-control" placeholder="" onChange={this.onChange} />
                                                 <span style={{color: "red"}}>{this.state.errors["Name_On_Account"]}</span>
                                             </div>
                                         </div>
                                         <div class="col-md-6">
                                             <div class="form-group">
                                                 <label>Account Number<span class="required">*</span> </label>
                                                 <input type="text" id="Account_Number" class="form-control" placeholder="" onChange={this.onChange} />
                                                 <span style={{color: "red"}}>{this.state.errors["Account_Number"]}</span>
                                             </div>
                                         </div>
                                         <div class="col-md-6">
                                             <div class="form-group">
                                                 <label>ABA Routing Number <span class="required">*</span> </label>
                                                 <input type="text" id="ABA_Routing_Number" class="form-control" placeholder="" onChange={this.onChange} />
                                                 <span style={{color: "red"}}>{this.state.errors["ABA_Routing_Number"]}</span>
                                             </div>
                                         </div>
                                         <div className="col-md-12">                                            
                                                <div className="custom-control custom-checkbox">
                                                <br />
                                                <input type="checkbox" id="save_ach" name="save_ach" className="custom-control-input" value="true" onClick={this.saveAch}/>
                                                <label className="custom-control-label" for="save_ach">Save this ACH on file for future payments</label>
                                            
                                                </div>
                                            </div>
                                        </div>
                                            </div>
                                        </div>
                                    :""}
                                </div>
                            </div>
                                :''} 



                                  
                        </div>
                               
                                </div>:""}
                                
                            </div>
                            { (this.state.submission == "Local Lab Submission")?
                            <div className="col-md-12 submission-option-detail">
                                <h2 className="title-2">Local Lab selection</h2>
                                <div className="form-group">
                                    <label for="lab_report_file">Please attach required Client Infectious Disease Lab Reports</label>
                                    <input type="file" className="form-control-file" id="lab_report_file" onChange={e => this.handleFileRead(e)}  />
                                    <span style={{color: "red"}}>{this.state.errors["lab_report_file"]}</span>
                                    </div>
                            </div>:''}

                           
                            <div className="col-md-12 submission-option-detail">
                                { (this.state.submission == "Waiver Submission")?
                                <div>
                                    <h2 className="title-2">Waiver selection</h2>
                                    <p>I agree to hold American Cell Technology harmless if i develop any infectious diseases at any time in the future subsequent to obtaining my stored cells.</p>
                                    <h3 className="title-3 mb-3">Please choose the appropriate Waiver notice</h3>
                                    <div className="choose-options-sec">
                                        <div className="custom-control custom-radio">
                                            <input type="radio" id="negative" name="WaiverNotice" value="Waiver notice for a client that tested negative or believes client has no known infection" className="custom-control-input" onChange={this.checkradio} />
                                            <label className="custom-control-label" for="negative">Waiver notice for a client that tested negative or believes client has no known infection</label>
                                            </div>
                                            <div className="custom-control custom-radio">
                                            <input type="radio" id="positive" name="WaiverNotice" value="Waiver notice for clients that have tested positive for a tested infection" className="custom-control-input" onChange={this.checkradio} />
                                            <label className="custom-control-label" for="positive">Waiver notice for clients that have tested positive for a tested infection</label>
                                            </div>

                                            <span style={{color: "red"}}>{this.state.errors["WaiverNotice"]}</span>
                                            
                                    </div>
                                    <br />
                                    { (this.state.client_test == "negative")?
                                    <div className="form-group">
                                        <p>
                                        Please be advised that I have either tested negative for infectious diseases for which ACT requires testing, or know that I do not have any infectious diseases. By signing this, I am aware that I cannot be infected by my cells currently stored with American Cell Technology as I am certain my cells were not contaminated with any of the diseases for which ACT requires testing. I agree to hold American Cell Technology harmless if I test positive or develop any infections at any time in the future subsequent to obtaining my stored cells.    
                                        </p>
                                    </div>:""}
                                    { (this.state.client_test == "positive")?
                                    <div className="form-group">
                                        <p>
                                        By signing the lines below, I know that I have tested positive for one or more of the chronic infections listed in the Infectious Disease Screening Requirement document and have written them below. I understand that American Cell Technology works under current Good Manufacturing Practices and takes necessary precautions to prevent cross contamination of my cell samples. As such, I waive my rights to any additional testing for specific infectious diseases for personal cells being returned to me. I understand that while I still have one or more chronic infections still present in my body, I cannot be further infected or claim to be infected after obtaining my cells from the bank. 
                                        </p>
                                    </div>                                
                                    :""}
                                    <div className="col-md-6 mb-3">
                                        {/* <p>By signing below, you confirm that you have read and fully understand the terms and conditions of this Agreement.</p> */}
                                        <label>Signature <span id="Signature"   className="act_required">*</span></label>
                                        <SignatureCanvas  ref={(ref) => { this.sigPad = ref }} penColor='white' backgroundColor="gray"  canvasProps={{width: 500, height: 100, className: 'sigCanvas'}} />
                                        <span style={{color: "red"}}> {this.state.errors["Signature"]}</span>
                                        <div>
                                        <button className="btn  btn-secondary" type="button"  onClick={this.clear}>
                                        Clear
                                        </button>  &nbsp;
                                        <button className="btn btn-primary" type="button"  onClick={this.trim}>
                                        Click to Confirm Signature
                                        </button>
                                        </div> 

                                        </div>

                                    </div>:""}


                                <p>At American Cell Technology (ACT), we highly encourage infectious disease screening, although it is not required. ACT requires each client to complete a lab selection or to complete waiver submission. This screening ensures that clients who may have been cured of an infectious disease between the time of their sample submission and the retrieval of their stored cells do not risk reinfecting themselves. The test detects any included infectious diseases present at the time of sample collection. Please see attached <a href="../ACT Infectious Disease Evaluation.pdf" target="_blank">ACT’s Infectious Disease Screening Requirement</a>. </p>
                                {/* <a target="_blank" href="https://americancelltechnology.my.salesforce.com/sfc/p/#3j0000002ViU/a/3j000000M1Yh/HrigTo_saZs4Gqw8wSg38nWafC_YTLiKz8hNMWwWvds"> </a> */}

                                {/* <p>As the Client, you have the option of using ACT’s White Glove Labs offering, going to a local lab facility and sending us your results, or signing a waiver holding ACT harmless.											
                                </p>

                                <strong> ACT White Glove Labs</strong>
                                <p>simplifies the blood draw process from the comfort of your home or office, all for a fixed price that is often more affordable than using your local lab. </p>

                                <strong> ACT White Glove Labs</strong>
                                <p> is completed through a few quick steps:</p>

                                <ul className="glove-steps">
                                    <li>Select and pay for the ACT White Glove Labs service offering and fill out the required infomration</li>
                                    <li>Schedule a convenient time for the Mobile Phlebotomist to come to you for blood draw at <a href="https://travalab.com/american-cell-technology" target="_blank"> www.travalab.com/american-cell-technology</a>. On Travalab Scheduling site, simply select American Cell Technology Phlebotomy and scroll to the bottom to request a convenient weekday (M-F) date and time for blood draw</li>
                                    <li>On date of service, provide Mobile Phlebotomist the blood draw kit sent to you by ACT and fill out blood draw date and time on Requisite Form included in your kit</li>
                                    <li>The Mobile Phlebotomist will handle shipping and processing your sample</li>
                                    <li>Let ACT to handle the rest!</li>
                                </ul> */}

                                <p>Clients have three options to fulfill this requirement: </p>


                                <ol>
                                <li><b><u>ACT White Glove Labs</u></b>: Complete the blood draw process at the physician's office (if available) for a fixed price, often more affordable than using a local lab. The process involves: 
                                <ul>
                                <li>Selecting and paying for the ACT White Glove Labs service through our portal. </li>
                                <li>Scheduling a blood draw appointment at the physician's office. </li>
                                <li>Using the provided blood draw kit and completing the Requisite Form with the date and time of the blood draw. </li>
                                <li>Shipping the sample to our processing facility via overnight delivery using the label included.</li>
                                </ul>
                                <p></p>
                                </li>
                                <li><b><u>Local Lab Facility</u></b>: Clients may also choose to visit a local lab for infectious disease screening. The results should be submitted to ACT through the portal or via email. <p></p></li>
                                <li><b><u>Waiver</u></b>: If a client opts not to undergo infectious disease screening, they can sign a waiver that releases ACT from any liability related to reinfection risk.</li>
                                </ol>
                                <p>If you have any questions, please contact us at 844-443-2355 or email us at info@americancelltechnology.com.</p>
                                <p>Understanding the Differences Between ACT White Glove Labs & Local Labs</p>


                                <div className="glove-table mt-0">
                                    <table width="100%" className="table table-bordered">
                                        <thead>
                                            <th>Feature</th>
                                            <th width="20%">ACT White Glove Labs</th>
                                            <th>Local Lab</th>
                                        </thead>
                                        <tr>
                                            <td>Price</td>
                                            <td width="20%">$399</td>
                                            <td>Varies by lab</td>
                                        </tr>
                                        <tr>
                                            <td>Lab script included</td>
                                            <td>Yes, handled by ACT</td>
                                            <td>No, physician must provide <br/>bloodwork script</td>
                                        </tr>
                                        
                                        <tr>
                                            <td>Convenience from medical office </td>
                                            <td>Yes, blood draw can be done<br/> at physician’s office (even <br/>on the same day as the<br/> sample collection)</td>
                                            <td>No, requires an additional <br/>visit to a local lab</td>
                                        </tr>
                                       
                                        <tr>
                                            <td>Results automatically submitted</td>
                                            <td>Yes, results sent directly to <br/>ACT</td>
                                            <td>No, client must submit <br/>results through the portal<br/> or via email</td>
                                        </tr>
                                        
                                    </table>
                                </div>

                                                               
                         


                                    <div className="form-group">
                                        <label>Any Infectious Diseases Client Has:</label>
                                        <textarea className="form-control" id="InfectiousDisease" onChange={this.onChange}></textarea>
                                    </div>

                                    <div className="form-group">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="agree" value="yes" onChange={this.onChange} />
                                        <label className="custom-control-label" for="agree"> I understand and agree to American Cell Technology's <a href>Infectious Disease Terms and Conditions</a></label><br />
                                        <span style={{color: "red"}}>{this.state.errors["agree"]}</span>
                                    </div>
                                    </div>

                                    <div className="form-group">
                                    <h2 className="title-2">American Cell Technology's Infectious Disease Terms and Conditions</h2>
                                    <p>American Cell Technology requires a series of infectious disease tests prior to storing your personal stem cells at our laboratory and cryopreservation facility. Stem cell banks that sell allogenic (different donor and recipient) stem cells could never distribute any cell known to be contaminated with a variety of common chronic viral disorders. American Cell Technology only works with autologous (your own) cells. The American Disabilities Act (ADA) prohibits discrimination against individuals with disabilities in everyday activities, including receipt of medical services.  Therefore, we cannot (nor do we wish to) discriminate against our clients who arguably have disabilities under the ADA due to having tested positive for various infectious diseases.  Accordingly, we have adopted policies that allow us to provide care for patients/clients potentially infected by a variety of chronic viral conditions (e.g. HIV, Hepatitis, etc.), while ensuring that patients are fully informed of the potential consequences of storing and being treated with potentially infected cells.</p>
                                    <p>American Cell Technology requires infectious disease testing in order to protect our clients. There is no risk to our employees or risk of cross contamination due to our strict standard operating procedures and how we handle all cell samples at our facility. There is also no risk of reinfecting you with your own cells, since the majority of the diseases we require testing for cannot currently be cured.</p>
                                    <p>However, Hepatitis C can be cured and we are hopeful that many other diseases like HIV and Hepatitis B will have cures in the near future. Because of this, it is mandatory that, should you be treated and cured for an infectious disease after providing your cell sample to us for storage and treatment, we are notified immediately. We would not want to send you back cells contaminated with a virus that you no longer have (which could put you at risk of being re-infected).  Your future cooperation in keeping us updated is therefore very important for your own health.</p>
                                    </div>

                                    
                                    
                                    <button type="submit" className="btn btn-primary">Submit</button>

                            </div>
                            </div>}
                            </div>
                            </form>
                        </div>
                    </div>
                    }
                        


                    </div>
                    </div>
                    </div>

            </MainLayout>              
            
        );
    }
}

InfectiousDisease.propTypes = {
    errors: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    setDiseaseData: PropTypes.object.isRequired,
    getPhysician: PropTypes.object.isRequired,
    getPaymentInfo: PropTypes.object.isRequired,
    verifyClientData: PropTypes.object.isRequired,
};



const mapStateToProps = (state) => ({
    page_name : state.pay,       
    errors: state.errors,
  });


export default connect(mapStateToProps, {setDiseaseData,getPhysician,getPaymentInfo,verifyClientData  })(InfectiousDisease);
